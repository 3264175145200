var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "employee-new-modal" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: { title: _vm.title, active: _vm.localShow },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c(
            "vs-popup",
            {
              staticClass: "popup90",
              attrs: {
                fullscreen: false,
                title: "Cadastrar Fornecedor",
                active: _vm.popupProvider,
              },
              on: {
                "update:active": function ($event) {
                  _vm.popupProvider = $event
                },
              },
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Tipo de Pessoa"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        clearable: false,
                        options: [
                          { label: "Física", value: "FISICA" },
                          { label: "Jurídica", value: "JURIDICA" },
                        ],
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Desculpe, nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.provider.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider, "type", $$v)
                        },
                        expression: "provider.type",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-2/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        label: "Nome/Razão Social",
                        "data-vv-scope": "provider",
                        "data-vv-as": "Nome/Razão Social",
                        name: "name",
                      },
                      model: {
                        value: _vm.provider.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider, "name", $$v)
                        },
                        expression: "provider.name",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("provider.name"),
                            expression: "errors.has('provider.name')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("provider.name")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["###.###.###-##", "##.###.###/####-##"],
                          expression: "['###.###.###-##','##.###.###/####-##']",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "cpf",
                          expression: "'cpf'",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: {
                        label: "CNPJ/CPF",
                        "data-vv-scope": "provider",
                        "data-vv-as": "CPF",
                        name: "cpf",
                      },
                      model: {
                        value: _vm.provider.cpf,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider, "cpf", $$v)
                        },
                        expression: "provider.cpf",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("provider.cpf"),
                            expression: "errors.has('provider.cpf')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("provider.cpf")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "IE/RG" },
                      model: {
                        value: _vm.provider.rg,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider, "rg", $$v)
                        },
                        expression: "provider.rg",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "vx-row" },
                [
                  _vm._l(_vm.provider.phones, function (phone, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "vx-col md:w-1/5 w-full" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: ["(##)#####-####", "(##)####-####"],
                              expression: "['(##)#####-####','(##)####-####']",
                            },
                          ],
                          staticClass: "w-full mt-2",
                          attrs: {
                            label:
                              phone.type === "phone"
                                ? "Telefone"
                                : phone.type === "mobile"
                                ? "Celular"
                                : phone.type === "other"
                                ? "Outro"
                                : "",
                          },
                          model: {
                            value: phone.phone,
                            callback: function ($$v) {
                              _vm.$set(phone, "phone", $$v)
                            },
                            expression: "phone.phone",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/5 w-full" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "email",
                            expression: "'email'",
                          },
                        ],
                        staticClass: "w-full mt-2",
                        attrs: {
                          label: "E-mail",
                          "data-vv-as": "E-mail",
                          "data-vv-scope": "provider",
                          type: "email",
                          name: "email",
                        },
                        model: {
                          value: _vm.provider.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.provider, "email", $$v)
                          },
                          expression: "provider.email",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("provider.email"),
                              expression: "errors.has('provider.email')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v(_vm._s(_vm.errors.first("provider.email")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/5 w-full mt-2" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v("Plano de contas"),
                      ]),
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.initial",
                            value: "required",
                            expression: "'required'",
                            modifiers: { initial: true },
                          },
                        ],
                        staticClass: "vue_select_drop_size_180",
                        attrs: {
                          "data-vv-as": "Plano de Contas",
                          "data-vv-scope": "provider",
                          name: "account_plan_id",
                          reduce: (option) => option.value,
                          options: _vm.accountPlans,
                          placeholder: "Selecione",
                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "no-options",
                            fn: function ({}) {
                              return [
                                _vm._v(
                                  "\n              Desculpe, nenhum resultado encontrado.\n            "
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.provider.account_plan_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.provider, "account_plan_id", $$v)
                          },
                          expression: "provider.account_plan_id",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("provider.account_plan_id"),
                              expression:
                                "errors.has('provider.account_plan_id')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v("Campo Obrigatório")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "flex items-end mt-2" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "MapPinIcon", svgClasses: "w-5 h-5" },
                  }),
                  _c("span", { staticClass: "leading-none font-medium" }, [
                    _vm._v("Endereço"),
                  ]),
                ],
                1
              ),
              _c("hr"),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["#####-###"],
                          expression: "['#####-###']",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: { label: "CEP", placeholder: "CEP" },
                      on: { keyup: _vm.searchZipCode },
                      model: {
                        value: _vm.provider.address.zip_code,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "zip_code", $$v)
                        },
                        expression: "provider.address.zip_code",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Logradouro"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        clearable: "",
                        options: _vm.logradouroOptions,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Desculpe, nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.provider.address.logradouro,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "logradouro", $$v)
                        },
                        expression: "provider.address.logradouro",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Endereço" },
                      model: {
                        value: _vm.provider.address.street,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "street", $$v)
                        },
                        expression: "provider.address.street",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        label: "Número",
                        type: "number",
                        placeholder: "S/N",
                      },
                      model: {
                        value: _vm.provider.address.number,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "number", $$v)
                        },
                        expression: "provider.address.number",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full mt-2",
                      attrs: { label: "Complemento" },
                      model: {
                        value: _vm.provider.address.complement,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "complement", $$v)
                        },
                        expression: "provider.address.complement",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full mt-2",
                      attrs: { label: "Bairro" },
                      model: {
                        value: _vm.provider.address.neighborhood,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "neighborhood", $$v)
                        },
                        expression: "provider.address.neighborhood",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4_ w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Cidade"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        placeholder: _vm.cityOptions.length
                          ? "Selecione"
                          : "Selecione um estado",
                        options: _vm.cityOptions,
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Desculpe, nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.provider.address.city_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "city_id", $$v)
                        },
                        expression: "provider.address.city_id",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Estado"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        options: _vm.states,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      on: {
                        input: function ($event) {
                          _vm.fillCity(_vm.provider.address.state_id),
                            (_vm.provider.address.city_id = null)
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Desculpe, nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.provider.address.state_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "state_id", $$v)
                        },
                        expression: "provider.address.state_id",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("País"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        clearable: false,
                        options: _vm.countryOptions,
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Desculpe, nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.provider.address.country,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "country", $$v)
                        },
                        expression: "provider.address.country",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("vs-divider"),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-4 flex flex-wrap items-center justify-end",
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-auto",
                          attrs: { color: "secondary" },
                          on: {
                            click: function ($event) {
                              _vm.popupProvider = false
                            },
                          },
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-4",
                          attrs: {
                            disabled: !_vm.validateProvider || _vm.disableSave,
                          },
                          on: { click: _vm.storeProvider },
                        },
                        [_vm._v("Salvar Alterações")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Responsável"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  attrs: {
                    reduce: (option) => option.value,
                    "data-vv-scope": "service-order",
                    "data-vv-as": "Responsável",
                    name: "employee_id",
                    clearable: true,
                    options: _vm.employees,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.serviceOrder.employee_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.serviceOrder, "employee_id", $$v)
                    },
                    expression: "serviceOrder.employee_id",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("service-order.employee_id"),
                        expression: "errors.has('service-order.employee_id')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.errors.first("service-order.employee_id"))
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/4 w-full mt-0" }, [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Fornecedor"),
              ]),
              _c(
                "div",
                { staticClass: "mt-0 flex flex-wrap items-center justify" },
                [
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: "required",
                        expression: "'required'",
                        modifiers: { initial: true },
                      },
                    ],
                    staticClass: "w-90__",
                    attrs: {
                      clearable: true,
                      filterable: true,
                      reduce: (option) => option.value,
                      options: _vm.providerOptions,
                      "data-vv-scope": "service-order",
                      "data-vv-as": "Fornecedor",
                      name: "provider_id",
                      placeholder: "Digite CPF/CNPJ ou nome do fornecedor...",
                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                    },
                    on: { search: _vm.debouncedGetSearchProvider },
                    scopedSlots: _vm._u([
                      {
                        key: "no-options",
                        fn: function ({}) {
                          return [
                            _vm._v(
                              "\n              Nenhum resultado encontrado.\n            "
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.serviceOrder.provider_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.serviceOrder, "provider_id", $$v)
                      },
                      expression: "serviceOrder.provider_id",
                    },
                  }),
                  _c("feather-icon", {
                    staticClass: "ml-auto inline-flex rounded-full",
                    attrs: {
                      title: "Novo Fornecedor",
                      icon: "UserPlusIcon",
                      svgClasses: "w-8 h-8 cursor-pointer hover:text-primary",
                    },
                    on: {
                      click: function ($event) {
                        _vm.popupProvider = true
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("service-order.provider_id"),
                      expression: "errors.has('service-order.provider_id')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("service-order.provider_id")))]
              ),
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Tipo"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  attrs: {
                    reduce: (option) => option.value,
                    "data-vv-scope": "service-order",
                    "data-vv-as": "Tipo",
                    name: "type",
                    clearable: false,
                    options: [
                      { value: "fuelSupply", label: "Abastecimento" },
                      { value: "vehicleMantenance", label: "Manutenção" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.serviceOrder.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.serviceOrder, "type", $$v)
                    },
                    expression: "serviceOrder.type",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("service-order.type"),
                        expression: "errors.has('service-order.type')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("service-order.type")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Total"),
                ]),
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input normal hasValue",
                  attrs: {
                    currency: { prefix: "R$ " },
                    valueAsInteger: false,
                    distractionFree: false,
                    precision: 2,
                    autoDecimalMode: true,
                    valueRange: { min: 0 },
                    allowNegative: false,
                  },
                  model: {
                    value: _vm.serviceOrder.total,
                    callback: function ($$v) {
                      _vm.$set(_vm.serviceOrder, "total", $$v)
                    },
                    expression: "serviceOrder.total",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "w-full" },
            _vm._l(_vm.serviceOrder.services, function (service, i) {
              return _c("div", { key: i, staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/6 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required|min_value:1",
                          expression: "'required|min_value:1'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        type: "number",
                        min: "1",
                        label: "Qtd.",
                        "data-vv-scope": "service-order",
                        "data-vv-as": "Qtd.",
                        name: "quantity_" + i,
                      },
                      model: {
                        value: service.quantity,
                        callback: function ($$v) {
                          _vm.$set(service, "quantity", $$v)
                        },
                        expression: "service.quantity",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has(
                              "service-order.quantity_" + i
                            ),
                            expression:
                              "errors.has('service-order.quantity_'+i)",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v("Mínimo 1")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Total"),
                    ]),
                    _c("currency-input", {
                      staticClass:
                        "w-full vs-inputx vs-input--input normal hasValue",
                      attrs: {
                        currency: { prefix: "R$ " },
                        valueAsInteger: false,
                        distractionFree: false,
                        precision: 2,
                        autoDecimalMode: true,
                        valueRange: { min: 0 },
                        allowNegative: false,
                      },
                      model: {
                        value: service.value,
                        callback: function ($$v) {
                          _vm.$set(service, "value", $$v)
                        },
                        expression: "service.value",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Serviço"),
                    ]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "vue_select_drop_size_160",
                      attrs: {
                        "data-vv-scope": "service-order",
                        "data-vv-as": "Serviço",
                        name: "service_" + i,
                        clearable: false,
                        options: _vm.serviceMaintenances,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "no-options",
                            fn: function ({}) {
                              return [
                                _vm._v(
                                  "\n              Desculpe, nenhum resultado encontrado.\n            "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: service.service,
                        callback: function ($$v) {
                          _vm.$set(service, "service", $$v)
                        },
                        expression: "service.service",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("service-order.service_" + i),
                            expression:
                              "errors.has('service-order.service_'+i)",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v("Obrigatório")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                  [
                    _c(
                      "vx-tooltip",
                      {
                        staticClass: "h-5 w-5 mt-5",
                        attrs: {
                          text: i ? "Remover Serviço" : "Adicionar Serviço",
                          position: "top",
                        },
                      },
                      [
                        i === 0
                          ? _c("feather-icon", {
                              staticClass: "cursor-pointer hover:text-primary",
                              attrs: {
                                icon: "PlusCircleIcon",
                                svgClasses: "w-10 h-10",
                              },
                              on: { click: _vm.addServiceField },
                            })
                          : _c("feather-icon", {
                              staticClass:
                                "text-danger cursor-pointer hover:text-primary",
                              attrs: {
                                icon: "MinusCircleIcon",
                                svgClasses: "w-10 h-10",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeServiceField(i)
                                },
                              },
                            }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-2",
                      attrs: { disabled: !_vm.validateFormServiceOrder },
                      on: { click: _vm.save },
                    },
                    [_vm._v("Cadastrar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: { type: "border", color: "secondary" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Cancelar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }